import {
  BOOLEAN_VALUE,
  MEDICAL_CONDITIONS,
} from '../../../constants/inputOptions'
import { RegistrationFormRawData } from '../RegistrationForm'

const parseBooleanStringToBoolean = (value: string): boolean =>
  value === BOOLEAN_VALUE.YES

const parseMedicalConditionSpecify = (
  medicalConditionSpecify: Array<boolean>,
): Array<string> =>
  medicalConditionSpecify.reduce(
    (list, isChecked, i) =>
      isChecked ? [...list, MEDICAL_CONDITIONS[i].value] : list,
    [],
  )

export interface RegistrationFormData {
  age: number
  gender: string
  country: string
  position: string
  isLivingAlone: boolean
  numberOfHouseholdMember: number
  isElderPresented: boolean
  isSelfMedicalCondition: boolean
  selfMedicalConditionSpecify: Array<string>
  isNonElderyMedicalCondition: boolean
  nonElderyMedicalConditionSpecify: Array<string>
}

export const parseRegistrationFormData = (
  values: RegistrationFormRawData,
): RegistrationFormData => ({
  ...values,
  isLivingAlone: parseBooleanStringToBoolean(values.isLivingAlone),
  isElderPresented: parseBooleanStringToBoolean(values.isElderPresented),
  isSelfMedicalCondition: parseBooleanStringToBoolean(
    values.isSelfMedicalCondition,
  ),
  selfMedicalConditionSpecify: parseMedicalConditionSpecify(
    values.selfMedicalConditionSpecify,
  ),
  isNonElderyMedicalCondition: parseBooleanStringToBoolean(
    values.isNonElderyMedicalCondition,
  ),
  nonElderyMedicalConditionSpecify: parseMedicalConditionSpecify(
    values.nonElderyMedicalConditionSpecify,
  ),
})
