import React from 'react'
import { RegistrationFormRawData } from './RegistrationForm'
import { Checkbox } from './formComponents'
import { RegistrationFormData } from './helper/formDataParser'
import { InputOption } from './formComponents/FormInterfaces'

interface MedicalConditionChoicesProps {
  description?: string
  options: InputOption<string>[]
  values: RegistrationFormRawData
  valuesKey: keyof RegistrationFormData
  handleChange: (e: React.ChangeEvent<Element>) => void
  errorMessage?: string | string[]
}

const MedicalConditionChoices = ({
  description = '',
  options,
  values,
  valuesKey,
  handleChange,
  errorMessage,
}: MedicalConditionChoicesProps): JSX.Element => (
  <div className="-mt-2 mb-8">
    <p>{description}</p>
    <p className="text-red-600 text-xs mt-1 mb-4">{errorMessage}</p>
    <div className="flex flex-col space-y-2">
      {options.map(({ value, text }, i) => (
        <Checkbox
          id={`${valuesKey}[${i}]`}
          value={values[valuesKey][i]}
          label={text}
          key={value}
          onChange={handleChange}
        />
      ))}
    </div>
  </div>
)

export default MedicalConditionChoices
