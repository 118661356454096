import React from 'react'
import { Formik } from 'formik'

import { SubmitButton, InputGroup, Checkbox } from './formComponents'
import TermsAndConditionsContent from '../TermsAndConditionsContent'
import { termsAndConditionsAgreementValidationSchema } from './helper/formValidationSchema'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../../constants/i18n'

interface AgreementFormData {
  isRead: boolean
  isAgreed: boolean
}
interface TermsAndConditionsDrawerProp {
  isOpen?: boolean
  onClose?: () => void
  onSubmit?: () => void
}

const TermsAndConditionsDrawer = ({
  isOpen,
  onClose,
  onSubmit,
}: TermsAndConditionsDrawerProp): JSX.Element => {
  const { t } = useTranslation(Namespace.Registration)

  const initialAgreementValues: AgreementFormData = {
    isRead: false,
    isAgreed: false,
  }

  return (
    <div
      className={`flex flex-col fixed w-screen overflow-y-scroll right-0 left-0 bottom-0 bg-white transition-height duration-1000 ease-in-out ${
        isOpen ? 'h-screen' : 'h-0'
      }`}
    >
      <div className="p-6">
        <TermsAndConditionsContent />

        <Formik
          initialValues={initialAgreementValues}
          validationSchema={termsAndConditionsAgreementValidationSchema(t)}
          onSubmit={(values: AgreementFormData, { setSubmitting }): void => {
            setSubmitting(true)
            onSubmit()
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            errors,
            isSubmitting,
            touched,
          }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <InputGroup
                inputId="isRead"
                errorMessage={touched.isRead && errors.isRead}
              >
                <Checkbox
                  id="isRead"
                  value={values.isRead}
                  onChange={handleChange}
                  label={t('confirmation.read')}
                />
              </InputGroup>
              <InputGroup
                inputId="isAgreed"
                errorMessage={touched.isRead && errors.isAgreed}
              >
                <Checkbox
                  id="isAgreed"
                  value={values.isAgreed}
                  onChange={handleChange}
                  label={t('confirmation.agreed')}
                />
              </InputGroup>
              <SubmitButton isDisabled={isSubmitting}>
                {t('signup')}
              </SubmitButton>
            </form>
          )}
        </Formik>
        <div className="flex justify-center">
          <button
            className="text-blue-700 font-bold my-4 mx-auto"
            onClick={onClose}
          >
            {t('back')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditionsDrawer
