import * as Yup from 'yup'
import INPUT_ERROR_MESSAGE from '../../../constants/inputErrorMessage'
import { BOOLEAN_VALUE } from '../../../constants/inputOptions'
import { TFunction } from 'i18next'

const YupRequiredNumberWithMinimum = (
  minimum: number,
  minimumErrorMessage: string,
  requiredErrorMessage: string,
) =>
  Yup.number().required(requiredErrorMessage).min(minimum, minimumErrorMessage)

const YupNotAnArrayOfAllFalse = (errorMessage: string) =>
  Yup.array(Yup.boolean())
    .compact((value) => !value)
    .required(errorMessage)

const YupIsTrue = (errorMessage: string) =>
  Yup.boolean().oneOf([true], errorMessage)

export const registrationValidationSchema = (t: TFunction) =>
  Yup.object({
    age: YupRequiredNumberWithMinimum(
      18,
      t(INPUT_ERROR_MESSAGE.UNDER_AGE),
      t(INPUT_ERROR_MESSAGE.REQUIRED),
    ),
    gender: Yup.string().required(t(INPUT_ERROR_MESSAGE.REQUIRED)),
    country: Yup.string().required(t(INPUT_ERROR_MESSAGE.REQUIRED)),
    position: Yup.string().required(t(INPUT_ERROR_MESSAGE.REQUIRED)),
    isLivingAlone: Yup.string().required(t(INPUT_ERROR_MESSAGE.REQUIRED)),
    numberOfHouseholdMember: Yup.number().when('isLivingAlone', {
      is: BOOLEAN_VALUE.NO,
      then: YupRequiredNumberWithMinimum(
        1,
        t(INPUT_ERROR_MESSAGE.REQUIRED),
        t(INPUT_ERROR_MESSAGE.REQUIRED),
      ),
    }),
    isElderPresented: Yup.string().when('isLivingAlone', {
      is: BOOLEAN_VALUE.NO,
      then: Yup.string().required(t(INPUT_ERROR_MESSAGE.REQUIRED)),
    }),
    isSelfMedicalCondition: Yup.string().required(
      t(INPUT_ERROR_MESSAGE.REQUIRED),
    ),
    selfMedicalConditionSpecify: Yup.array().when('isSelfMedicalCondition', {
      is: BOOLEAN_VALUE.YES,
      then: YupNotAnArrayOfAllFalse(t(INPUT_ERROR_MESSAGE.REQUIRED)),
    }),
    isNonElderyMedicalCondition: Yup.string().when('isLivingAlone', {
      is: BOOLEAN_VALUE.NO,
      then: Yup.string().required(t(INPUT_ERROR_MESSAGE.REQUIRED)),
    }),
    nonElderyMedicalConditionSpecify: Yup.array().when(
      'isNonElderyMedicalCondition',
      {
        is: BOOLEAN_VALUE.YES,
        then: YupNotAnArrayOfAllFalse(t(INPUT_ERROR_MESSAGE.REQUIRED)),
      },
    ),
  })

export const termsAndConditionsAgreementValidationSchema = (t: TFunction) =>
  Yup.object({
    isRead: YupIsTrue(t(INPUT_ERROR_MESSAGE.REQUIRED)),
    isAgreed: YupIsTrue(t(INPUT_ERROR_MESSAGE.REQUIRED)),
  })
