import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layouts/Layout'
import RegistrationForm from '../components/RegistrationForm/RegistrationForm'
import { RegistrationFormData } from '../components/RegistrationForm/helper/formDataParser'
import TermsAndConditionsDrawer from '../components/RegistrationForm/TermsAndConditionsDrawer'
import SEO from '../components/seo'
import BetaBanner from '../components/BetaBanner'

import { MessageID } from '../constants/lineMessage'
import { getUsersCollection, withTimestampField } from '../utils/firestore'
import { Namespace } from '../constants/i18n'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useLiff } from '../utils/liff'
import { DisplayLarge } from '../components/typography'

function useIggyHandwashLogo() {
  const { iggywashLogo } =
    useStaticQuery(graphql`
      query {
        iggywashLogo: file(relativePath: { eq: "iggywash-logo.png" }) {
          childImageSharp {
            fixed(width: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `) || {}

  return iggywashLogo
}

const RegisterPage = (): JSX.Element => {
  const [error, setError] = useState(null)
  const [registrationFormData, setRegistrationFormData] = useState({})
  const [
    isTermsAndConditionsDrawerOpen,
    setIsTermsAndConditionsDrawerOpen,
  ] = useState(false)
  const { liff, profile, loading, error: liffError } = useLiff({
    loadLanguage: true,
    notAllowRegisteredUser: true,
  })
  const iggywashLogo = useIggyHandwashLogo()
  const { t } = useTranslation(Namespace.Registration)

  if (loading) {
    return <div className="max-w-3xl mx-auto p-6">loading...</div>
  }
  if (liffError) {
    setError(liffError)
  }
  if (!profile) {
    setError('Please register from Line bot')
  }

  const onSubmitRegistrationForm = (data: RegistrationFormData): void => {
    setRegistrationFormData(data)
    setIsTermsAndConditionsDrawerOpen(true)
  }

  const submitDataToFirebase = async (): Promise<void> => {
    try {
      await getUsersCollection()
        .doc(profile.userId)
        .set(
          withTimestampField({
            userId: profile.userId,
            isRegistered: true,
            ...registrationFormData,
          }),
        )

      await liff.sendLineMessageAndClose(MessageID.registerSuccess)
    } catch (error) {
      setError(error.message || error)
    }
  }

  return (
    <>
      <SEO title="Registration" />

      <BetaBanner />
      <Layout className="py-6">
        <div className="flex flex-row">
          <div className="flex-1">
            <DisplayLarge className="mb-2">{t('title')}</DisplayLarge>
          </div>
          <div className="pl-2">
            {iggywashLogo && (
              <Img fixed={iggywashLogo.childImageSharp.fixed} alt="" />
            )}
          </div>
        </div>

        {error ? (
          <span className="text-red-600">Error: {error}</span>
        ) : (
          <>
            <p>{t('greeting', { name: profile.displayName })}</p>
            <RegistrationForm onSubmit={onSubmitRegistrationForm} />
            <TermsAndConditionsDrawer
              isOpen={isTermsAndConditionsDrawerOpen}
              onClose={(): void => setIsTermsAndConditionsDrawerOpen(false)}
              onSubmit={submitDataToFirebase}
            />
          </>
        )}
      </Layout>
    </>
  )
}

export default RegisterPage
