import React from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  BOOLEAN_VALUE,
  MEDICAL_CONDITIONS,
  GENDER,
  COUNTRY,
  POSITION,
  BOOLEAN,
} from '../../constants/inputOptions'
import {
  InputGroup,
  NumberInput,
  RadioButtonGroup,
  Dropdown,
  SubmitButton,
} from '../RegistrationForm/formComponents'
import MedicalConditionChoices from './MedicalConditionChoices'
import {
  parseRegistrationFormData,
  RegistrationFormData,
} from './helper/formDataParser'
import { registrationValidationSchema } from './helper/formValidationSchema'
import { Namespace } from '../../constants/i18n'
import { InputOption } from './formComponents/FormInterfaces'

export interface RegistrationFormRawData {
  age: number
  gender: string
  country: string
  position: string
  isLivingAlone: string
  numberOfHouseholdMember: number
  isElderPresented: string
  isSelfMedicalCondition: string
  selfMedicalConditionSpecify: Array<boolean>
  isNonElderyMedicalCondition: string
  nonElderyMedicalConditionSpecify: Array<boolean>
}

export const initialFormValue: RegistrationFormRawData = {
  age: 0,
  gender: '',
  country: 'THA',
  position: '',
  isLivingAlone: '',
  numberOfHouseholdMember: 0,
  isElderPresented: '',
  isSelfMedicalCondition: '',
  selfMedicalConditionSpecify: MEDICAL_CONDITIONS.map(() => false),
  isNonElderyMedicalCondition: '',
  nonElderyMedicalConditionSpecify: MEDICAL_CONDITIONS.map(() => false),
}

interface RegistrationFormProps {
  onSubmit?: (data: RegistrationFormData) => void
}

const RegistrationForm = ({ onSubmit }: RegistrationFormProps): JSX.Element => {
  const { t } = useTranslation(Namespace.Registration)

  const translateInputOptions = (
    options: InputOption<string>[],
  ): InputOption<string>[] =>
    options.map((option) => ({ ...option, text: t(option.text) }))

  return (
    <div>
      <Formik
        initialValues={initialFormValue}
        validationSchema={registrationValidationSchema(t)}
        onSubmit={(values: RegistrationFormRawData): void =>
          onSubmit(parseRegistrationFormData(values))
        }
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
        }): JSX.Element => (
          <form onSubmit={handleSubmit} noValidate>
            <h3 className="font-bold mb-2 mt-8 text-gray-800">
              {t('personalInformation')}
            </h3>

            <InputGroup
              label={t('age')}
              inputId="age"
              errorMessage={touched.age && errors.age}
            >
              <NumberInput
                id="age"
                onChange={handleChange}
                placeholder={t('agePlaceholder')}
                required
              />
            </InputGroup>

            <InputGroup
              label={t('gender')}
              inputId="gender"
              errorMessage={touched.gender && errors.gender}
            >
              <RadioButtonGroup
                id="gender"
                options={translateInputOptions(GENDER)}
                value={values.gender}
                onChange={handleChange}
                required
              />
            </InputGroup>

            <InputGroup
              label={t('country')}
              inputId="country"
              errorMessage={touched.country && errors.country}
            >
              <Dropdown
                id="country"
                options={COUNTRY}
                value={values.country}
                onChange={handleChange}
                placeholder={t('countryPlaceholder')}
                defaultValue={initialFormValue.country}
                required
              />
            </InputGroup>

            <InputGroup
              label={t('position')}
              inputId="position"
              errorMessage={touched.position && errors.position}
            >
              <RadioButtonGroup
                id="position"
                options={translateInputOptions(POSITION)}
                value={values.position}
                onChange={handleChange}
                required
              />
            </InputGroup>

            <h3 className="font-bold mb-2 mt-12 text-gray-800">
              {t('householdInformation')}
            </h3>

            <InputGroup
              label={t('isLivingAlone')}
              inputId="isLivingAlone"
              errorMessage={touched.isLivingAlone && errors.isLivingAlone}
            >
              <RadioButtonGroup
                id="isLivingAlone"
                options={translateInputOptions(BOOLEAN)}
                value={values.isLivingAlone}
                onChange={handleChange}
                required
              />
            </InputGroup>

            {values.isLivingAlone === BOOLEAN_VALUE.NO && (
              <>
                <InputGroup
                  label={t('numberOfHouseholdMember')}
                  inputId="numberOfHouseholdMember"
                  errorMessage={
                    touched.numberOfHouseholdMember &&
                    errors.numberOfHouseholdMember
                  }
                >
                  <NumberInput
                    id="numberOfHouseholdMember"
                    onChange={handleChange}
                    placeholder={t('numberOfHouseholdMemberPlaceholder')}
                    required
                  />
                </InputGroup>

                <InputGroup
                  label={t('isElderPresented')}
                  description={t('isElderPresentedDescription')}
                  inputId="isElderPresented"
                  errorMessage={
                    touched.isElderPresented && errors.isElderPresented
                  }
                >
                  <RadioButtonGroup
                    id="isElderPresented"
                    options={translateInputOptions(BOOLEAN)}
                    value={values.isElderPresented}
                    onChange={handleChange}
                    required
                  />
                </InputGroup>
              </>
            )}

            <InputGroup
              label={t('medicalCondition')}
              description={t('medicalConditionDescription')}
              inputId="isSelfMedicalCondition"
              errorMessage={
                touched.isSelfMedicalCondition && errors.isSelfMedicalCondition
              }
            >
              <RadioButtonGroup
                id="isSelfMedicalCondition"
                options={translateInputOptions(BOOLEAN)}
                value={values.isSelfMedicalCondition}
                onChange={handleChange}
                required
              />
            </InputGroup>

            {values.isSelfMedicalCondition === BOOLEAN_VALUE.YES && (
              <MedicalConditionChoices
                description={t('medicalConditionInstruction')}
                options={translateInputOptions(MEDICAL_CONDITIONS)}
                values={values}
                valuesKey="selfMedicalConditionSpecify"
                handleChange={handleChange}
                errorMessage={
                  touched.selfMedicalConditionSpecify &&
                  errors.selfMedicalConditionSpecify
                }
              />
            )}

            {values.isLivingAlone === BOOLEAN_VALUE.NO && (
              <>
                <InputGroup
                  label={t('nonElderyMedicalCondition')}
                  description={t('medicalConditionInstruction')}
                  inputId="isNonElderyMedicalCondition"
                  errorMessage={
                    touched.isNonElderyMedicalCondition &&
                    errors.isNonElderyMedicalCondition
                  }
                >
                  <RadioButtonGroup
                    id="isNonElderyMedicalCondition"
                    options={translateInputOptions(BOOLEAN)}
                    value={values.isNonElderyMedicalCondition}
                    onChange={handleChange}
                    required
                  />
                </InputGroup>

                {values.isNonElderyMedicalCondition === BOOLEAN_VALUE.YES && (
                  <MedicalConditionChoices
                    description={t('medicalConditionInstruction')}
                    options={translateInputOptions(MEDICAL_CONDITIONS)}
                    values={values}
                    valuesKey="nonElderyMedicalConditionSpecify"
                    handleChange={handleChange}
                    errorMessage={
                      touched.nonElderyMedicalConditionSpecify &&
                      errors.nonElderyMedicalConditionSpecify
                    }
                  />
                )}
              </>
            )}

            <SubmitButton className="mt-8 mb-4">{t('continue')}</SubmitButton>

            <p className="text-xs">{t('continueNote')}</p>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default RegistrationForm
